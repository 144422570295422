import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, UserCredential, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "@angular/fire/auth";
import { doc, docData, Firestore,  setDoc } from "@angular/fire/firestore";
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {takeUntil, take} from 'rxjs/operators'
import { UserData } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserData= null;
  logout$: Subject<boolean> = new Subject<boolean>();

  constructor(private firestore: Firestore, private auth: Auth, private router: Router) {
    onAuthStateChanged(this.auth,user =>{
      //console.log("User Changed: ", user);
      if(user){
        const userDoc=doc(this.firestore,`users/${user.uid}`);
        docData(userDoc,{idField: 'id'}).pipe(
          takeUntil(this.logout$),
          take(1)
        ).subscribe(data =>{
          this.currentUserData=data;
        })
      }else{
        this.currentUserData=null;
      }
    })
   }
   


   login({email, password}){
    
     return signInWithEmailAndPassword(this.auth, email,password);
   }
   async signup({email,password}, data: UserData): Promise<UserCredential>{
     try{
       const credentials = await createUserWithEmailAndPassword(this.auth, email, password);
       const userDoc = doc(this.firestore, `users/${credentials.user.uid}`);
       await setDoc(userDoc, data);
       return credentials;
     }catch (err){
       throw(err);
     }
   }

   async logout(){
     await signOut(this.auth);
     this.logout$.next(true);
     this.router.navigateByUrl("/", {replaceUrl: true});
   }
}
